import { Asset } from './asset';
import { EFingerprintTitle, Fingerprint, FingerprintPublicMetadata } from './fingerprint';
import { CreateBaseItemData, EItemVisibility, Item } from './item';
import { RemoteFile } from './remote-file';
import { SKU } from './sku';
import { User } from './user';

export enum EStatusFilter {
  // Authentication
  PROTECTED = 'protected',
  UNABLE_TO_PROTECT = 'unableToProtect',
  VERIFIED = 'verified',
  UNABLE_TO_VERIFY = 'unableToVerify',
  NOT_VERIFIED = 'notVerified',
  // Identification
  REGISTERED = 'registered',
  UNABLE_TO_REGISTER = 'unableToRegister',
  IDENTIFIED = 'identified',
  UNABLE_TO_IDENTIFY = 'unableToIdentify',
  NOT_IDENTIFIED = 'notIdentified',
  // Classification
  SKUS_REGISTERED = 'skusRegistered',
  UNABLE_TO_REGISTER_SKUS = 'unableToRegisterSkus',
  CLASSIFIED = 'classified',
  UNABLE_TO_CLASSIFY = 'unableToClassify',
  NOT_CLASSIFIED = 'notClassified',
  // Other
  REPORTED = 'reported',
  LOGS = 'logs',
}

export enum EJobStatus {
  OPEN = 'OPEN',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum EJobResultCode {
  OK = 0,
  NOT_VERIFIED = 1,
  ERROR_BE = 2,
  ERROR_CONNECTION = 3,
  ERROR_QUALITY = 4,
  ERROR_BLURRY = 5,
  ERROR_AREA = 6,
  ERROR_OVERLAP = 7,
}

export enum EJobResultType {
  OK,
  FAILED,
  NOT_VERIFIED,
}

export enum EJobTitle {
  ANY = 'any',
  // Worker - Authentication
  PROTECTION = 'protection',
  VERIFICATION = 'verification',
  VISUALISATION = 'visualisation',
  MATCHES_VISUALISATION = 'matchesVisualisation',
  // Worker - Identification
  REGISTRATION = 'registration',
  IDENTIFICATION = 'identification',
  DELETE_REGISTRATION = 'deleteRegistration',
  // Worker - Classification
  CLASSIFICATION_REGISTRATION = 'classificationRegistration',
  CLASSIFICATION_CLASSIFICATION = 'classification',
  CLASSIFICATION_DELETE_REGISTRATION = 'classificationDeleteRegistration',
  // Other
  GENERIC_LOG = 'genericLog',
  DIAGNOSTIC_LOG = 'diagnosticLog',
  BATCH_AUTHENTICATION_VERIFICATION_SCAN = 'batchAuthenticationVerificationScan',
  CLASSIFICATION_SCAN = 'classificationScan',
}

export enum ETaskName {
  // Worker - Authentication
  PROTECTION = 'protection',
  VERIFICATION = 'verification',
  VISUALISATION = 'visualisation',
  MATCHES_VISUALISATION = 'matches_visualisation',
  // Worker - Identification
  IDENTIFICATION_REGISTRATION = 'identification_registration',
  IDENTIFICATION_IDENTIFICATION = 'identification_identification',
  IDENTIFICATION_DELETE_REGISTRATION = 'identification_delete_registration',
  // Worker - Classification
  CLASSIFICATION_REGISTRATION = 'classification_registration',
  CLASSIFICATION_CLASSIFICATION = 'classification_classification',
  CLASSIFICATION_DELETE_REGISTRATION = 'classification_delete_registration',
  // Other
  OTHER = 'other',
  GENERIC_LOG = 'general_log',
  DIAGNOSTIC_LOG = 'diagnostic_log',
  BATCH_AUTHENTICATION_VERIFICATION_SCAN = 'batch_authentication_verification_scan',
  CLASSIFICATION_SCAN = 'classification_scan',
}

export enum FlagType {
  Correct = 'correct',
  Incorrect = 'incorrect',
  Neutral = 'neutral',
}

export interface JobResult {
  code: EJobResultCode | number;
  message: number;
}

export interface JobPublicMetadata {
  vertical?: string;
  blurDebugJson?: any;
  blurDetectionFlag?: string | number;
  verificationId?: string;
  verificationTaskId?: string;
  skuId?: string;
  visualisationVersion?: string;

  visualisationJob?: Job;
  matchesVisualisationJob?: Job;

  elapsedTime?: number;
  elapsedTimeNoInput?: number;

  [key: string]: any;
}

export interface Job {
  id: string;
  title: EJobTitle;
  status: EJobStatus;
  tenantId: string;
  item?: Item;
  sku?: SKU;
  createdAt: Date;
  startedAt?: Date;
  finishedAt?: Date;
  resultMessage?: string;
  resultCode?: EJobResultCode;
  time?: number;
  publicMetadata?: JobPublicMetadata;
  user: User;
  flag?: FlagType;
  flaggedAt?: Date;
  feedback?: string;

  // Only if specified in request 'withTasks'
  tasks?: Task[];
}

export interface IdentificationCandidate {
  itemId: string;
  confidence: number;
}

export interface TaskPublicMetadata {
  fingerprintTitle?: EFingerprintTitle | string;
  fingerprintPublicMetadata?: {
    location?: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
  };
  verificationId?: string;
  blurDetectionFlag?: string | number;
  candidates?: IdentificationCandidate[];
  visualisationVersion?: string;

  visualisationTask?: Task;

  [key: string]: any;
}

export interface Task {
  id: string;
  workerId: string;
  taskName: ETaskName;
  workerQueue: string;
  status: EJobStatus;
  resultMessage: string;
  resultCode: EJobResultCode;
  publicMetadata: TaskPublicMetadata;
  fingerprint?: Fingerprint;
  time?: number;
  file?: RemoteFile;
  assets?: Asset[];
}

export interface CreateJobData {
  title: EJobTitle;
  tenantId: string;
  itemId: string;
  verticalId?: string;
  workerQueue?: string;
  jobPublicMetadata?: JobPublicMetadata;
}

export interface CreateTaskData {
  fileId: string;
  publicMetadata?: any;
  fingerprintId?: string;
  verticalId?: string;
  workerQueue?: string;
  debug?: string;
}

export interface CreateProtectionJobData {
  tenantId: string;
  itemId: string;
  fileId: string;
  verticalId?: string;
  workerQueue?: string;
  jobPublicMetadata?: JobPublicMetadata;
  fingerprintPublicMetadata?: FingerprintPublicMetadata;
}

export interface CreateVerificationJobData {
  tenantId: string;
  itemId: string;
  fileId: string;
  verticalId?: string;
  workerQueue?: string;
  jobPublicMetadata?: JobPublicMetadata;
  fingerprintTitle?: EFingerprintTitle | string;
}

export interface CreateVisualisationJobData {
  tenantId: string;
  jobId: string;
}

export interface CreateVisualisationTaskData {
  taskId: string;
  publicMetadata?: TaskPublicMetadata;
}

export interface CreateRegistrationJobData {
  tenantId: string;
  skuId: string;
  item: CreateBaseItemData;
  fileId: string;
  verticalId?: string;
  workerQueue?: string;
  jobPublicMetadata?: JobPublicMetadata;
  fingerprintPublicMetadata?: FingerprintPublicMetadata;
  debug?: string;
}

export interface CreateIdentificationJobData {
  tenantId: string;
  skuId: string;
  fileId: string;
  verticalId?: string;
  workerQueue?: string;
  jobPublicMetadata?: JobPublicMetadata;
  visibility?: EItemVisibility;
}
