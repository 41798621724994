import { Query } from 'react-query';
import {
  EJobResultCode,
  EJobResultType,
  EJobStatus,
  EJobTitle,
  EStatusFilter,
  ETaskName,
  FlagType,
  Job,
  Task,
} from '../types/job';
import { FilterOperator } from './filter-operators';
import { Tenant } from '../types/tenant';

export const REFETCH_RUNNING_JOBS_INTERVAL = 500;

export const REFETCH_OPEN_JOBS_INTERVAL = 1500;

export const getResultStatus = (event: Job | Task): EJobResultType => {
  switch (event?.resultCode) {
    default:
      return EJobResultType.FAILED;
    case EJobResultCode.OK:
      return EJobResultType.OK;
    case EJobResultCode.NOT_VERIFIED:
      return EJobResultType.NOT_VERIFIED;
  }
};

export const isEventRunnable = (event: Job | Task): boolean => {
  const title = 'title' in event ? event.title : event.taskName;
  return ![
    EJobTitle.GENERIC_LOG,
    ETaskName.GENERIC_LOG,
    EJobTitle.DIAGNOSTIC_LOG,
    ETaskName.DIAGNOSTIC_LOG,
    EJobTitle.BATCH_AUTHENTICATION_VERIFICATION_SCAN,
    ETaskName.BATCH_AUTHENTICATION_VERIFICATION_SCAN,
    EJobTitle.CLASSIFICATION_SCAN,
    ETaskName.CLASSIFICATION_SCAN,
  ].includes(title);
};

export const getJobRunningStatus = (event?: Job | Task): boolean =>
  [EJobStatus.OPEN, EJobStatus.RUNNING].includes(event?.status);

export const getJobTitle = (title: EJobTitle | ETaskName) => {
  switch (title) {
    case EJobTitle.PROTECTION:
    case ETaskName.PROTECTION:
      return 'Protection';
    case EJobTitle.VERIFICATION:
    case ETaskName.VERIFICATION:
      return 'Verification';
    case EJobTitle.VISUALISATION:
    case ETaskName.VISUALISATION:
      return 'Visualisation';
    case EJobTitle.REGISTRATION:
    case ETaskName.IDENTIFICATION_REGISTRATION:
      return 'Item Registration';
    case EJobTitle.IDENTIFICATION:
    case ETaskName.IDENTIFICATION_IDENTIFICATION:
      return 'Identification';
    case EJobTitle.DELETE_REGISTRATION:
    case ETaskName.IDENTIFICATION_DELETE_REGISTRATION:
      return 'Delete Item Registration';
    case EJobTitle.CLASSIFICATION_REGISTRATION:
    case ETaskName.CLASSIFICATION_REGISTRATION:
      return 'SKU Registration';
    case EJobTitle.CLASSIFICATION_CLASSIFICATION:
    case ETaskName.CLASSIFICATION_CLASSIFICATION:
      return 'Classification';
    case EJobTitle.CLASSIFICATION_DELETE_REGISTRATION:
    case ETaskName.CLASSIFICATION_DELETE_REGISTRATION:
      return 'Delete SKU Registration';
    case EJobTitle.GENERIC_LOG:
    case ETaskName.GENERIC_LOG:
      return 'Generic Log';
    case EJobTitle.DIAGNOSTIC_LOG:
    case ETaskName.DIAGNOSTIC_LOG:
      return 'Diagnostic Log';
    case EJobTitle.BATCH_AUTHENTICATION_VERIFICATION_SCAN:
    case ETaskName.BATCH_AUTHENTICATION_VERIFICATION_SCAN:
      return 'Batch Authentication Verification Scan';
    case EJobTitle.CLASSIFICATION_SCAN:
    case ETaskName.CLASSIFICATION_SCAN:
      return 'Classification Scan';
    default:
      return 'Unknown';
  }
};

export const getTaskTitle = (task: Task) => getJobTitle(task.taskName);

export const getTitle = (event: Job | Task) =>
  getJobTitle((event as Job).title ?? (event as Task).taskName);

export const getJobRefetchInterval = (event: Job | Task | Task[], query: Query): number | false => {
  const evt = Array.isArray(event) ? event : [event];

  if (evt?.some((e) => e?.status === EJobStatus.RUNNING)) {
    return REFETCH_RUNNING_JOBS_INTERVAL;
  }

  if (evt?.some((e) => e?.status === EJobStatus.OPEN)) {
    return REFETCH_OPEN_JOBS_INTERVAL;
  }

  if (query.state.error) {
    return false;
  }

  return event ? false : REFETCH_OPEN_JOBS_INTERVAL;
};

export const getJobDetailUrl = (
  job: Job,
  tenant: Tenant,
  source: 'jobs' | 'skus' | 'items' = 'jobs',
) => {
  switch (job.title) {
    case EJobTitle.PROTECTION:
    case EJobTitle.VERIFICATION:
      return `/tenants/${tenant?.id}/items/${job.item?.id}/job/${job.id}?source=${source}`;
    case EJobTitle.REGISTRATION:
    case EJobTitle.IDENTIFICATION:
    case EJobTitle.DELETE_REGISTRATION:
      return `/tenants/${tenant?.id}/skus/${job.sku?.id ?? job.item?.sku?.id}/job/${
        job.id
      }?source=${source}`;
    default:
      return `/tenants/${tenant?.id}/events/${job.id}?source=${source}`;
  }
};

export const getVerificationResultTitle = (event: Job | Task) => {
  if (getJobRunningStatus(event)) {
    return 'Verification in progress';
  }

  if (event.resultCode === EJobResultCode.OK) {
    return 'Verified';
  }

  if (event.resultCode === EJobResultCode.NOT_VERIFIED) {
    return 'Not Verified';
  }

  return 'Unable To Verify';
};

export enum EJobsView {
  ALL = 'all',
  PROTECTED = 'protected',
  UNABLE_TO_PROTECT = 'unableToProtect',
  VERIFIED = 'verified',
  UNABLE_TO_VERIFY = 'unableToVerify',
  NOT_VERIFIED = 'notVerified',
  REGISTERED = 'registered',
  UNABLE_TO_REGISTER = 'unableToRegister',
  IDENTIFIED = 'identified',
  UNABLE_TO_IDENTIFY = 'unableToIdentify',
  NOT_IDENTIFIED = 'notIdentified',
  SKUS_REGISTERED = 'skusRegistered',
  UNABLE_TO_REGISTER_SKUS = 'unableToRegisterSkus',
  CLASSIFIED = 'classified',
  UNABLE_TO_CLASSIFY = 'unableToClassify',
  NOT_CLASSIFIED = 'notClassified',
  REPORTED = 'reported',
  LOGS = 'logs',
}

export const getJobsRequestStatusParams = (status?: EStatusFilter | EJobsView) => {
  const params: any = {
    'globalFilter[title]': {
      operator: FilterOperator.NOT_IN_ARRAY,
      value: [EJobTitle.VISUALISATION, EJobTitle.MATCHES_VISUALISATION],
    },
  };

  switch (status) {
    default:
    case EJobsView.ALL:
      break;

    /// Authentication ///
    case EJobsView.PROTECTED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.PROTECTION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_PROTECT:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.PROTECTION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.VERIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.VERIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_VERIFY:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.VERIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_IN_ARRAY,
        value: [EJobResultCode.OK, EJobResultCode.NOT_VERIFIED],
      };
      break;
    case EJobsView.NOT_VERIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.VERIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.NOT_VERIFIED,
      };
      break;

    /// Identification ///
    case EJobsView.REGISTERED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.REGISTRATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_REGISTER:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.REGISTRATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.IDENTIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.IDENTIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.NOT_IDENTIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.IDENTIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_IDENTIFY:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.IDENTIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_IN_ARRAY,
        value: [EJobResultCode.OK, EJobResultCode.NOT_VERIFIED],
      };
      break;

    /// Classification ///
    case EJobsView.SKUS_REGISTERED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.CLASSIFICATION_REGISTRATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_REGISTER_SKUS:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.CLASSIFICATION_REGISTRATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.CLASSIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.CLASSIFICATION_CLASSIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_CLASSIFY:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.CLASSIFICATION_CLASSIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_IN_ARRAY,
        value: [EJobResultCode.OK, EJobResultCode.NOT_VERIFIED],
      };
      break;
    case EJobsView.NOT_CLASSIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.CLASSIFICATION_CLASSIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.NOT_VERIFIED,
      };
      break;

    /// Reporting ///
    case EJobsView.REPORTED:
      params['globalFilter[flag]'] = FlagType.Incorrect;
      break;

    /// Logs ///
    case EJobsView.LOGS:
      params['globalFilter[title]'] = {
        operator: FilterOperator.IN_ARRAY,
        value: [EJobTitle.GENERIC_LOG, EJobTitle.DIAGNOSTIC_LOG],
      };
      break;
  }

  return params;
};
