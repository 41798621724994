import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { DateRange } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { VerifiedUser as ProtectedIcon, Help as UnableToVerifyIcon } from '@material-ui/icons';
import { CheckCircle as VerifiedIcon } from '../../../../icons/check-circle';
import { XCircle as NotVerifiedIcon } from '../../../../icons/x-circle';
import { Flag as ReportedIcon } from '../../../../icons/flag';
import { DocumentText as LogIcon } from '../../../../icons/document-text';
import { useAuth } from '../../../../hooks/use-auth';
import { ListResponse, ResponseData } from '../../../../types/axios';
import { useAxios } from '../../../../hooks/use-axios';
import { EStatusFilter, Job } from '../../../../types/job';
import { Badge } from '../../../../components/badge';
import { Tenant, TenantType } from '../../../../types/tenant';
import { FilterOperator } from '../../../../utils/filter-operators';
import { getJobsRequestStatusParams } from '../../../../utils/jobs-helper';

interface JobsBadgesProps {
  range: DateRange<Date>;
}

const getParams = ({
  range,
  tenant,
  status,
}: {
  range: DateRange<Date>;
  tenant?: Tenant;
  status: EStatusFilter;
}) => {
  const [from, to] = range;
  let params: any = {
    start: 0,
    length: 0,
    'globalFilter[tenant]': tenant?.id,
    'globalFilter[startedAt]': {
      operator: FilterOperator.BETWEEN,
      value: [from.toISOString(), new Date(to.setHours(23, 59, 59)).toISOString()],
    },
    onlyCount: true,
  };

  params = {
    ...params,
    ...getJobsRequestStatusParams(status),
  };

  return params;
};

const JOBS_URL = '/jobs';

export const JobsBadges: FC<JobsBadgesProps> = ({ range }) => {
  const { tenant, getTenantTypes } = useAuth();
  const { axios } = useAxios();

  /// Authentication ///

  // Protected
  const { data: protectedCount, isLoading: isProtectedCountLoading } = useQuery(
    ['jobs-protected', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.PROTECTED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.AUTHENTICATION),
    },
  );

  // Verfied
  const { data: verifiedCount, isLoading: isVerifiedCountLoading } = useQuery(
    ['jobs-verified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.VERIFIED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.AUTHENTICATION),
    },
  );

  // Unable To Verify
  const { data: unableToVerifyCount, isLoading: isUnableToVerifyCountLoading } = useQuery(
    ['jobs-unable-to-verify', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.UNABLE_TO_VERIFY }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.AUTHENTICATION),
    },
  );

  // Not Verified
  const { data: notVerifiedCount, isLoading: isNotVerifiedCountLoading } = useQuery(
    ['jobs-not-verified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.NOT_VERIFIED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.AUTHENTICATION),
    },
  );

  /// Identification ///

  // Registered
  const { data: registeredCount, isLoading: isRegisteredCountLoading } = useQuery(
    ['jobs-registered', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.REGISTERED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.IDENTIFICATION),
    },
  );

  // Identified
  const { data: identifiedCount, isLoading: isIdentifiedCountLoading } = useQuery(
    ['jobs-identified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.IDENTIFIED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.IDENTIFICATION),
    },
  );

  // Unable To Identify
  const { data: unableToIdentifyCount, isLoading: isUnableToIdentifyCountLoading } = useQuery(
    ['jobs-unable-to-identify', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.UNABLE_TO_IDENTIFY }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.IDENTIFICATION),
    },
  );

  // Not Identified
  const { data: notIdentifiedCount, isLoading: isNotIdentifiedCountLoading } = useQuery(
    ['jobs-not-identified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.NOT_IDENTIFIED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.IDENTIFICATION),
    },
  );

  /// Classification ///

  // SKUs Registered
  const { data: skusRegisteredCount, isLoading: isSkusRegisteredCountLoading } = useQuery(
    ['jobs-skus-registered', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.SKUS_REGISTERED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.CLASSIFICATION),
    },
  );

  // Classified
  const { data: classifiedCount, isLoading: isClassifiedCountLoading } = useQuery(
    ['jobs-classified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.CLASSIFIED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.CLASSIFICATION),
    },
  );

  // Unable To Classify
  const { data: unableToClassifyCount, isLoading: isUnableToClassifyCountLoading } = useQuery(
    ['jobs-unable-to-classify', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.UNABLE_TO_CLASSIFY }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.CLASSIFICATION),
    },
  );

  // Not Classified
  const { data: notClassifiedCount, isLoading: isNotClassifiedCountLoading } = useQuery(
    ['jobs-not-classified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.NOT_CLASSIFIED }),
      });

      return recordsFiltered;
    },
    {
      enabled: getTenantTypes().includes(TenantType.CLASSIFICATION),
    },
  );

  /// Other ///

  // Reported
  const { data: reportedCount, isLoading: isReportedCountLoading } = useQuery(
    ['jobs-reported', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.REPORTED }),
      });

      return recordsFiltered;
    },
  );

  // Logs
  const { data: logsCount, isLoading: isLogsCountLoading } = useQuery(
    ['jobs-logs', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.LOGS }),
      });

      return recordsFiltered;
    },
  );

  return (
    <Grid
      container
      spacing={3}
      sx={{
        '.MuiGrid-grid-lg-true': {
          flexBasis: '210px',
        },
      }}
    >
      {getTenantTypes().includes(TenantType.AUTHENTICATION) && (
        <Grid item lg sm={4} xs={6}>
          {/* Protected */}

          <Badge
            title="Protected"
            value={protectedCount}
            loading={isProtectedCountLoading}
            icon={
              <ProtectedIcon
                sx={{
                  color: 'text.secondary',
                }}
              />
            }
          />
        </Grid>
      )}
      {/* Verified */}
      {getTenantTypes().includes(TenantType.AUTHENTICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Verified"
            value={verifiedCount}
            loading={isVerifiedCountLoading}
            icon={
              <VerifiedIcon
                sx={{
                  color: 'text.secondary',
                }}
              />
            }
          />
        </Grid>
      )}
      {/* Unable To Verify */}
      {getTenantTypes().includes(TenantType.AUTHENTICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Unable To Verify"
            value={unableToVerifyCount}
            loading={isUnableToVerifyCountLoading}
            icon={
              <UnableToVerifyIcon
                sx={{
                  color: 'text.secondary',
                }}
              />
            }
          />
        </Grid>
      )}
      {/* Not Verified */}
      {getTenantTypes().includes(TenantType.AUTHENTICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Not Verified"
            value={notVerifiedCount}
            loading={isNotVerifiedCountLoading}
            icon={<NotVerifiedIcon sx={{ color: 'error.main' }} />}
          />
        </Grid>
      )}

      {/* Registered */}
      {getTenantTypes().includes(TenantType.IDENTIFICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Registered"
            value={registeredCount}
            loading={isRegisteredCountLoading}
            icon={
              <ProtectedIcon
                sx={{
                  color: 'text.secondary',
                }}
              />
            }
          />
        </Grid>
      )}
      {/* Identified */}
      {getTenantTypes().includes(TenantType.IDENTIFICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Identified"
            value={identifiedCount}
            loading={isIdentifiedCountLoading}
            icon={
              <VerifiedIcon
                sx={{
                  color: 'text.secondary',
                }}
              />
            }
          />
        </Grid>
      )}
      {/* Unable To Identify */}
      {getTenantTypes().includes(TenantType.IDENTIFICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Unable To Identify"
            value={unableToIdentifyCount}
            loading={isUnableToIdentifyCountLoading}
            icon={
              <UnableToVerifyIcon
                sx={{
                  color: 'text.secondary',
                }}
              />
            }
          />
        </Grid>
      )}
      {/* Not Identified */}
      {getTenantTypes().includes(TenantType.IDENTIFICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Not Identified"
            value={notIdentifiedCount}
            loading={isNotIdentifiedCountLoading}
            icon={<NotVerifiedIcon sx={{ color: 'error.main' }} />}
          />
        </Grid>
      )}

      {/* SKUs Registered */}
      {getTenantTypes().includes(TenantType.CLASSIFICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="SKUs Registered"
            value={skusRegisteredCount}
            loading={isSkusRegisteredCountLoading}
            icon={<ProtectedIcon sx={{ color: 'text.secondary' }} />}
          />
        </Grid>
      )}
      {/* Classified */}
      {getTenantTypes().includes(TenantType.CLASSIFICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Classified"
            value={classifiedCount}
            loading={isClassifiedCountLoading}
            icon={<VerifiedIcon sx={{ color: 'text.secondary' }} />}
          />
        </Grid>
      )}
      {/* Unable To Classify */}
      {getTenantTypes().includes(TenantType.CLASSIFICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Unable To Classify"
            value={unableToClassifyCount}
            loading={isUnableToClassifyCountLoading}
            icon={<UnableToVerifyIcon sx={{ color: 'text.secondary' }} />}
          />
        </Grid>
      )}
      {/* Not Classified */}
      {getTenantTypes().includes(TenantType.CLASSIFICATION) && (
        <Grid item lg sm={4} xs={6}>
          <Badge
            title="Not Classified"
            value={notClassifiedCount}
            loading={isNotClassifiedCountLoading}
            icon={<NotVerifiedIcon sx={{ color: 'error.main' }} />}
          />
        </Grid>
      )}

      {/* Reported */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Reported"
          value={reportedCount}
          loading={isReportedCountLoading}
          icon={<ReportedIcon sx={{ color: 'error.main' }} />}
        />
      </Grid>
      {/* Logs */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Logs"
          value={logsCount}
          loading={isLogsCountLoading}
          icon={<LogIcon sx={{ color: 'text.secondary' }} />}
        />
      </Grid>
    </Grid>
  );
};

JobsBadges.propTypes = {
  range: PropTypes.any,
};
