import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Link, Typography } from '@material-ui/core';
import ImageNotSupportedIcon from '@material-ui/icons/ImageNotSupported';
import { Ban as DeactivateItemIcon } from '../../../../icons/ban';
import { Job } from '../../../../types/job';
import { useAuth } from '../../../../hooks/use-auth';
import { getThumbnailUrl } from '../../../../utils/assets-helper';

interface JobsItemCellProps {
  job: Job;
}

export const JobsItemCell: FC<JobsItemCellProps> = ({ job }) => {
  const { tenant } = useAuth();

  if (!job.item && !job.sku) {
    return (
      <Typography variant="body2" color="inherit">
        -
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Avatar
        alt={job.item?.title || job.sku?.title || job.item?.sku?.title}
        src={getThumbnailUrl(job.item || job.sku)}
        sx={{
          width: 64,
          height: 64,
          '& > img': {
            objectFit: 'contain',
          },
        }}
        variant="rounded"
      >
        <ImageNotSupportedIcon fontSize="small" />
      </Avatar>

      <Box sx={{ ml: 2 }}>
        <Link
          color="inherit"
          component={RouterLink}
          sx={{ display: 'block' }}
          to={
            job.item
              ? `/tenants/${tenant?.id}/items/${job.item?.id}/timeline?source=jobs#${job.id}`
              : `/tenants/${tenant?.id}/skus/${job.sku?.id}/timeline?source=jobs#${job.id}`
          }
          underline="none"
          variant="subtitle2"
        >
          {job.item ? (
            <Box>
              <Box display="flex">
                {job.item?.publicMetadata?.deactivated && (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{
                      mr: 1,
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                    title="Deactivated item"
                  >
                    <DeactivateItemIcon />
                  </Typography>
                )}
                <Typography
                  variant="subtitle1"
                  noWrap
                  maxWidth={{
                    md: 200,
                    lg: 350,
                    xl: 650,
                  }}
                  sx={{
                    textDecoration: 'underline',
                  }}
                >
                  {job.item?.title}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                {job.item?.uniqueId}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                noWrap
                maxWidth={{
                  md: 200,
                  lg: 350,
                  xl: 650,
                }}
                sx={{
                  textDecoration: 'underline',
                }}
              >
                Show in SKU
              </Typography>
            </Box>
          )}
        </Link>
      </Box>
    </Box>
  );
};

JobsItemCell.propTypes = {
  job: PropTypes.any.isRequired,
};
